import briefing from 'components/bricks/components/shared/components/briefing-content/components';
import output from 'components/bricks/components/shared/components/output-tab';
import settings from './settings';
import setup from './setup';
import icon from './icon.svg';
import validators from './validators';
import defaultData from './default-data';
import settingsInputs from './settings-inputs';
import placements from '../data/placements';
import { channels } from '../data/channels';
export default { settings, setup, output, icon, validators, defaultData, briefing, settingsInputs, placements, channels };
