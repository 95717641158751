import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'multiSocial_ad',
    title: 'Multi social ad',
    channel: 'social',
    platform: 'multi',
    description: 'Create an ad for a multi social adset',
    addBrickText: 'Add ad',
    availableParentBricks: ['multiSocial_adset'],
    availableChildBricks: [],
    outputAction: 'publish',
    defaultTab: 'creatives',
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'creatives',
            title: 'Creatives',
            type: 'component',
            component: 'creatives'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
