import moment from 'moment';
import GeneralHelpers from 'components/bricks/helpers/general.helpers';
import placementsSection from '../data/placements';

const start_time = moment().format('YYYY-MM-DD HH:mm:ss');
const end_time = moment().add(5, 'd').format('YYYY-MM-DD HH:mm:ss');

const defaultData = {
    data: {
        settings: {
            targeting: {
                placements: GeneralHelpers.extractPlacementKeys(placementsSection)
            },
            start_time,
            end_time
        }
    }
};

export default defaultData;
