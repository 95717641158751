import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const metadata: IEditorBlock[] = [
    {
        type: 'subSection',
        opened: true,
        title: 'Copy',
        items: [
            {
                type: 'textMultiLanguage',
                model: 'name',
                label: 'Ad Name',
                tooltip: 'Maximum length is 375 characters',
                maxLength: 375
            },
            {
                type: 'textMultiLanguage',
                model: 'headline',
                label: 'Headline',
                tooltip: 'Maximum length is 34 characters',
                maxLength: 34
            },
            {
                type: 'textMultiLanguage',
                model: 'link',
                label: 'Link',
                tooltip: 'Maximum length of the link is 34 characters',
                maxLength: 34
            },
            {
                type: 'textMultiLanguage',
                model: 'cta',
                label: 'Call to Action',
                tooltip: 'Call to action'
            }
        ]
    }
];

export default metadata;
