import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'snapchat_adsquad',
    title: 'Snapchat ad squad',
    channel: 'social',
    platform: 'snapchat',
    outputAction: 'publish',
    description: 'Create an ad squad for a Snapchat campaign',
    addBrickText: 'Add ad squad',
    availableParentBricks: ['snapchat_campaign'],
    availableChildBricks: ['snapchat_ad'],
    additionalVars: ['snapchat_campaign'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'component',
            component: 'settings'
        },
        {
            key: 'preview',
            title: 'Preview',
            type: 'component',
            component: 'preview'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        }
    ]
};

export default setup;
