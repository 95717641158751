const validators: any = {
    requiredLevels: {
        parent: 'snapchat_campaign'
    },
    validators: [
        {
            condition: "brick.data.customize_name && (!brick.data.name || brick.data.name==='')",
            message: 'Snapchat ad squad should have name'
        },
        {
            condition: '!brick.data.bid_micro',
            message: 'Snapchat ad squad should have bid price'
        },
        {
            condition: '!brick.data.budget || !brick.data.budget==="0"',
            message: 'Snapchat ad squad should have budget'
        },
        {
            condition: '!brick.data.optimization_goal',
            message: 'Snapchat ad squad should have optimization goal'
        },
        {
            condition: '!brick.data.bid_strategy',
            message: 'Snapchat ad squad should have bid strategy'
        },
        {
            condition: 'brick.data.bid_micro && brick.data.brick_micro<0.1',
            message: 'Snapchat ad squad should have bid strategy more than 0.1'
        },
        {
            condition: '!brick.data.targeting || !brick.data.targeting.geos || !brick.data.targeting.geos.length',
            message: 'Snapchat ad squad should have location'
        },
        {
            condition:
                "brick.data.bid_strategy && (brick.data.bid_strategy==='LOWEST_COST_WITH_MAX_BID' || brick.data.bid_strategy==='TARGET_COST') && !brick.data.bid_micro",
            message: "Bid micro is required when set's bid strategy is 'Lowest cos with max bid' or 'Target cost'"
        },
        {
            condition: "!brick.data.roas_value_micro && brick.data.bid_strategy==='MIN_ROAS'",
            message: "Roas value should be specified when bid strategy==='MIN_ROAS'"
        },
        {
            condition: '!brick.data.budget || !brick.data.delivery_constraint',
            message: 'Daily budget or budget micro should be specified on set level'
        },
        {
            condition: "brick.data.placement_v2 && JSON.stringify(brick.data.placement_v2).includes('SNAP_ADS') && brick.data.included_content_types",
            message: "Included content type can't be specified when placement contains 'Snap ads'"
        },
        {
            condition:
                "brick.data.placement_v2 && JSON.stringify(brick.data.placement_v2).includes('USER_STORIES') && (brick.data.included_content_types || brick.data.excluded_content_types)",
            message: "Included and excluded content type can't be specified when placement contains 'User stories'"
        },
        {
            condition: '!brick.data.placement_v2 || !brick.data.placement_v2.config',
            message: 'Snapchat ad squad must have placement type'
        },
        {
            condition: 'brick.data.placement_v2.config==="CUSTOM" && (!brick.data.placement_v2.platforms || !brick.data.placement_v2.platforms.length)',
            message: 'Snapchat ad squad with custom placement should have platform specified'
        },
        {
            condition:
                'brick.data.placement_v2.config==="CUSTOM" && (!brick.data.placement_v2.snapchat_positions || !brick.data.placement_v2.snapchat_positions.length)',
            message: 'Snapchat ad squad with custom placement should have positions'
        },
        {
            condition:
                "brick.data.placement_v2 && JSON.stringify(brick.data.placement_v2).includes('DISCOVER_FEED') && (brick.data.included_content_types || brick.data.excluded_content_types)",
            message: "Included and excluded content type can't be specified when placement contains 'Discover feed'"
        },
        {
            condition:
                "['PIXEL_PURCHASE', 'PIXEL_PAGE_VIEW', 'PIXEL_ADD_TO_CART', 'APP_PURCHASE', 'APP_SIGN_UP', 'APP_ADD_TO_CART', 'SWIPES'].includes(brick.data.optimization_goal) && (!brick.data.targeting || !brick.data.targeting.product_audiences)",
            message: 'Set for retargeting must include product_audiences in targeting'
        },
        {
            condition:
                "['PIXEL_PURCHASE', 'PIXEL_PAGE_VIEW', 'PIXEL_ADD_TO_CART', 'APP_PURCHASE', 'APP_SIGN_UP', 'APP_ADD_TO_CART', 'SWIPES'].includes(brick.data.optimization_goal) && (!brick.data.pixel_id)",
            message: 'Set for retargeting must include Pixel id'
        },
        {
            condition:
                "!['PIXEL_PURCHASE', 'PIXEL_PAGE_VIEW', 'PIXEL_ADD_TO_CART', 'APP_PURCHASE', 'APP_SIGN_UP', 'APP_ADD_TO_CART', 'SWIPES'].includes(brick.data.optimization_goal) && brick.data.targeting && brick.data.targeting.product_audiences",
            message: 'Set for prospecting must omit product_audiences in targeting'
        },
        {
            condition:
                "brick.data.bid_strategy && brick.data.bid_strategy==='AUTOBID' && !['APP_INSTALLS', 'IMPRESSIONS', 'STORY_OPENS', 'SWIPES', 'USES', 'VIDEO_VIEWS', 'VIDEO_VIEWS_15_SEC', 'PIXEL_PURCHASE', 'PIXEL_SIGNUP', 'PIXEL_PAGE_VIEW', 'PIXEL_ADD_TO_CART', 'APP_PURCHASE', 'APP_SIGNUP', 'APP_ADD_TO_CART', 'APP_REENGAGE_PURCHASE', 'APP_REENGAGE_OPEN'].includes(brick.data.optimization_goal)",
            message: "Set with bid strategy 'AUTOBID' can have specific optimization goals"
        },
        {
            condition: "brick.data.bid_strategy && brick.data.bid_strategy==='MIN_ROAS' && !['APP_PURCHASE'].includes(brick.data.optimization_goal)",
            message: "Set with bid strategy 'MIN_ROAS' can have App purchase as optimization goal"
        },
        {
            condition:
                "brick.data.bid_strategy && brick.data.bid_strategy==='TARGET_COST' && !['APP_INSTALLS', 'SWIPES', 'USES', 'VIDEO_VIEWS_15_SEC', 'PIXEL_PURCHASE', 'PIXEL_SIGNUP', 'PIXEL_PAGE_VIEW', 'PIXEL_ADD_TO_CART', 'APP_PURCHASE', 'APP_SIGNUP', 'APP_ADD_TO_CART', 'APP_REENGAGE_PURCHASE', 'APP_REENGAGE_OPEN', 'STORY_OPENS'].includes(brick.data.optimization_goal)",
            message: "Set with bid strategy 'TARGET_COST' can have specific optimization goals"
        },
        {
            condition:
                "brick.data.pacing_type && brick.data.pacing_type==='ACCELERATED' && (brick.data.bid_strategy!=='LOWEST_COST_WITH_MAX_BID' || !brick.data.bid_micro || !['IMPRESSIONS', 'USES', 'SWIPES', 'VIDEO_VIEWS', 'VIDEO_VIEWS_15_SEC','STORY_OPENS'].includes(brick.data.optimization_goal))",
            message:
                "Set with pacing type 'ACCELERATED' should have bid strategy set to 'Lowest cos with max bid', set bid, and optimization goal shoul be 'IMPRESSIONS', 'USES', 'SWIPES', 'VIDEO_VIEWS', 'VIDEO_VIEWS_15_SEC' or 'STORY_OPENS'"
        }
    ]
};

export default validators;
