import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'settings.title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Publish',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'settings.publish.channels.pinterest.billing_group',
                label: 'Billing group',
                placeholder: "e.g. 'campaign-31231'",
                helperText:
                    'Billing group name that maps the campaign to the billing line item that appears on the invoice. If not specified, the campaign charge falls back to the advertiser billing configurations.',
                useValueObject: false
            },
            {
                type: 'select',
                model: 'settings.publish.channels.pinterest.objective_type',
                label: 'Objective',
                tooltip: 'The ultimate goal of your marketing campaign.',
                options: {
                    AWARENESS: 'Awareness',
                    WEB_CONVERSION: 'Web conversion',
                    CONSIDERATION: 'Consideration',
                    CATALOG_SALES: 'Catalog sales',
                    VIDEO_VIEW: 'Video view'
                }
            },
            {
                type: 'buttonGroup',
                model: 'settings.publish.channels.pinterest.is_managed',
                label: 'Managed',
                defaultValue: '',
                tooltip: 'Determines if a campaign is internally managed.',
                options: {
                    false: 'false',
                    true: 'true'
                }
            },
            {
                type: 'text',
                model: 'settings.publish.channels.pinterest.measurement_partner_campaign_id',
                label: 'Mesurement partner campaign id',
                placeholder: "e.g. 'campaign-31231'",
                helperText: 'Mobile measurement partner campaign ID.',
                condition: "campaign.bricks.settings.publish.channels.pinterest.objective_type==='APP_INSTALL'",
                useValueObject: false
            },
            {
                type: 'buttonGroup',
                model: 'settings.publish.channels.pinterest.mobile_measurement_partner',
                label: 'Mobile mesurement partner',
                defaultValue: '',
                tooltip: 'Determines if a campaign is internally managed.',
                condition: "campaign.bricks.settings.publish.channels.pinterest.objective_type==='APP_INSTALL'",
                options: {
                    ADJUST: 'ADJUST',
                    APPSFLYER: 'APPSFYER',
                    APSALAR: 'APSALAR',
                    BRANCH: 'BRANCH',
                    KOCHAVA: 'KOCHAVA',
                    SINGULAR: 'SINGULAR',
                    TUNE: 'TUNE'
                }
            },
            {
                type: 'text',
                model: 'settings.publish.channels.pinterest.tracking_urls',
                label: 'Tracking URLs',
                placeholder: "e.g. '{{'impression': ['URL1', 'URL2'], 'click': ['URL1', 'URL2', 'URL3']}}'",
                helperText: 'Third-party tracking URLs.',
                useValueObject: false
            },
            {
                type: 'date',
                model: 'settings.publish.channels.pinterest.start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'settings.publish.channels.pinterest.end_time',
                condition: "campaign.bricks.settings.publish.channels.pinterest.lifetime_spend_cap==='0'",
                label: 'End date'
            },
            {
                type: 'number',
                tooltip: 'Daily campaign budget',
                model: 'settings.publish.channels.pinterest.daily_spend_cap',
                label: 'Daily budget'
            },
            {
                type: 'number',
                tooltip: 'Lifetime campaign budget',
                model: 'settings.publish.channels.pinterest.lifetime_spend_cap',
                label: 'Lifetime spend cap'
            },
            {
                type: 'buttonGroup',
                model: 'settings.publish.channels.pinterest.is_campaign_budget_optimization',
                label: 'Budget optimization',
                defaultValue: 'false',
                tooltip:
                    'Determines if a campaign automatically generate ad-group level budgets given a campaign budget to maximize campaign outcome. When transitioning from non-cbo to cbo, all previous child ad group budget will be cleared.',
                options: {
                    false: 'OFF',
                    true: 'ON'
                }
            }
        ]
    },

    {
        title: 'Planning',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'dateRange',
                model: 'settings.planning',
                label: 'Schedule date & time'
            }
        ]
    }
];

export default settings;
