const validators: any = {
    validators: [
        {
            condition: "!brick.data.customize_name && (!brick.title || brick.title ==='')",
            message: 'Snapchat campaign brick should have title to be copied dfor publishing'
        },
        {
            condition: "brick.data.customize_name && (!brick.data.name || brick.data.name ==='')",
            message: 'Snapchat campaign should have publish name if you want to customize it'
        },
        {
            condition: '!brick.data.ad_account_id',
            message: 'Snapchat campaign should have ad account id'
        },
        {
            condition: '!brick.data.start_time',
            message: 'Snapchat campaign should have start time'
        },
        {
            condition: '!brick.data.status',
            message: 'Snapchat campaign should have status'
        }
    ]
};

export default validators;
