import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Budget and schedule',
        itemType: 'block',
        type: 'multiInput',
        blockModel: '{{{MODEL_PUBLISH_METADATA}}}',
        opened: true,
        items: [
            {
                type: 'date',
                model: 'adset_start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'adset_end_time',
                label: 'End date'
            },
            {
                type: 'select',
                model: 'pacing',
                label: 'Pacing',
                tooltip: 'Determines how your ads budget is spent over time.',
                options: {
                    STANDART: 'Standart',
                    NO_PACING: 'No pacing'
                }
            },

            {
                type: 'buttonGroup',
                model: 'adset_budget_mode',
                label: 'Budget type',
                tooltip: 'Type of the budget',
                options: {
                    DAILY: 'Daily',
                    LIFETIME: 'Lifetime'
                }
            },
            {
                type: 'number',
                tooltip: 'Budget',
                model: 'adset_budget',
                label: 'Budget'
            }
        ]
    },
    {
        title: 'Optimization & delivery',
        itemType: 'block',
        type: 'multiInput',
        blockModel: '{{{MODEL_PUBLISH_METADATA}}}',
        opened: true,
        items: [
            {
                type: 'number',
                tooltip: 'Bid cap or target cost for this ad set.',
                model: 'bid',
                label: 'Bid amount'
            },
            {
                type: 'select',
                model: 'bid_type',
                label: 'Bid strategy',
                tooltip:
                    'Choose bid strategy for this campaign to suit your specific business goals. Each strategy has tradeoffs and may be available for certain optimization_goals',
                options: {
                    WITHOUT_CAP: 'Lowest cost without cap',
                    WITH_CAP: 'Lowest cost with bid cap'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                label: 'Billing event',
                tooltip: 'The billing event for this ad set',
                options: {
                    IMPRESSIONS: 'Impressions',
                    CLICKS: 'Clicks'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                label: 'Optimization goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    REACH: 'Reach',
                    APP_INSTALLS: 'App installs',
                    IN_APP_EVENT: 'In app event',
                    IMPRESSIONS: 'Impressions',
                    VIDEO_VIEW: 'Video view',
                    LEAD_GENERATION: 'Lead generation',
                    PAGE_LIKES: 'Page likes',
                    PAGE_ENGAGEMENT: 'Page engagement',
                    VALUE: 'Value',
                    LANDING_PAGE_VIEWS: 'Landing page views'
                }
            }
        ]
    },
    {
        title: 'Targeting',
        itemType: 'block',
        type: 'multiInput',
        blockModel: '{{{MODEL_PUBLISH_METADATA}}}',
        opened: true,
        items: [
            {
                type: 'autocomplete',
                model: 'countries',
                label: 'Countries',
                options: ['Netherlands', 'Belgium']
            },
            {
                type: 'autocomplete',
                model: 'languages',
                label: 'Languages',
                options: ['Dutch', 'English']
            }
            //TODO: To be discussed
            // {
            //     type: 'checkboxList',
            //     model: 'targeting.user_os',
            //     label: 'Genders',
            //     selectType: 'multiple',
            //     options: {
            //         iOS: 'iOS',
            //         Android: 'Android'
            //     }
            // },
            // {
            //     type: 'checkboxList',
            //     model: 'targeting.genders',
            //     label: 'Genders',
            //     selectType: 'multiple',
            //     options: {
            //         MALE: 'Male',
            //         FEMALE: 'Female'
            //     }
            // },
            // {
            //     type: 'number',
            //     tooltip: 'Age from',
            //     model: 'targeting.age.from',
            //     label: 'Age from'
            // },
            // {
            //     type: 'number',
            //     tooltip: 'Age to',
            //     model: 'targeting.age.to',
            //     label: 'Age to'
            // },
            // {
            //     type: 'autocomplete',
            //     inputType: 'taglist',
            //     model: 'targeting.interests',
            //     label: 'Interests',
            //     options: ['Programming', 'Workout', 'Fishing']
            // }
        ]
    },

    {
        title: 'Planning',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'date',
                model: 'dateStart',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'dateEnd',
                label: 'End date'
            }
        ]
    }
];

export default settings;
