/* eslint-disable no-useless-escape */
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import { ValidationObject } from 'components/bricks/types/validator.type';
import { RegexHelpers } from 'helpers/regex.helpers';

// Ad setup data from the brick object
const brickAdSetup = 'brick.data?.adSetup?';

// Ad setup is of type 'single'
const isSingleAdSetup = `brick.data?.adSetup?.type === 'single'`;

// Ad setup is of type 'multi'
const isMultiAdSetup = `brick.data?.adSetup?.type === 'multi'`;

// Targeting placements from the parent settings
const parentSettingsTargeting = `parent?.data?.settings?.targeting?.placements?`;

const validators: ValidationObject = {
    validators: [
        {
            condition: `!${brickAdSetup}`,
            message: 'There is no data in ad setup',
            inputIdentifiers: ['snapchat_ad-ad_setup'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0] && !${brickAdSetup}.items[0].creativeIds?.length`,
            message: 'There are no creatives',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0] && !${brickAdSetup}.items[0].creativeIds?.length`,
            message: 'There are no creatives in the first frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1] && !${brickAdSetup}.items[1].creativeIds?.length`,
            message: 'There are no creatives in the second frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-1'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2] && !${brickAdSetup}.items[2].creativeIds?.length`,
            message: 'There are no creatives in the third frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-2'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3] && !${brickAdSetup}.items[3].creativeIds?.length`,
            message: 'There are no creatives in the fourth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-3'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4] && !${brickAdSetup}.items[4].creativeIds?.length`,
            message: 'There are no creatives in the fifth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-4'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5] && !${brickAdSetup}.items[5].creativeIds?.length`,
            message: 'There are no creatives in the sixth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-5'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6] && !${brickAdSetup}.items[6].creativeIds?.length`,
            message: 'There are no creatives in the seventh frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-6'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7] && !${brickAdSetup}.items[7].creativeIds?.length`,
            message: 'There are no creatives in the eigth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-7'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8] && !${brickAdSetup}.items[8].creativeIds?.length`,
            message: 'There are no creatives in the ninth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-8'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9] && !${brickAdSetup}.items[9].creativeIds?.length`,
            message: 'There are no creatives in the tenth frame',
            inputIdentifiers: ['snapchat_ad-ad_setup-creative_input-9'],
            severity: 'error',
            tabKey: 'adSetup'
        },

        // Snapchat Single Ad
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'snapchat-ad'),
            message: 'Snapchat Ad has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-ad-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Snapchat Story
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'snapchat-story'),
            message: 'Snapchat Story has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story'),
            message: 'Snapchat Story in first frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story', 1),
            message: 'Snapchat Story in second frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story', 2),
            message: 'Snapchat Story in third frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story', 3),
            message: 'Snapchat Story in fourth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story', 4),
            message: 'Snapchat Story in fifth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story', 5),
            message: 'Snapchat Story in sixth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story', 6),
            message: 'Snapchat Story in seventh frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story', 7),
            message: 'Snapchat Story in eight frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story', 8),
            message: 'Snapchat Story in ninth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-story', 9),
            message: 'Snapchat Story in tenth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-story-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Snapchat Collection
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection'),
            message: 'Snapchat Collection in first frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection', 1),
            message: 'Snapchat Collection in second frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection', 2),
            message: 'Snapchat Collection in third frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection', 3),
            message: 'Snapchat Collection in fourth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection', 4),
            message: 'Snapchat Collection in fifth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection', 5),
            message: 'Snapchat Collection in sixth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection', 6),
            message: 'Snapchat Collection in seventh frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection', 7),
            message: 'Snapchat Collection in eight frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection', 8),
            message: 'Snapchat Collection in ninth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'snapchat-collection', 9),
            message: 'Snapchat Collection in tenth frame has not assigned creative',
            inputIdentifiers: ['snapchat_ad-ad_setup-placements-snapchat-collection-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'name'),
            message: 'Name is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 0, 'name'),
            message: 'Name in first frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 1, 'name'),
            message: 'Name in second frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 2, 'name'),
            message: 'Name in third frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 3, 'name'),
            message: 'Name in fourth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 4, 'name'),
            message: 'Name in fifth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 5, 'name'),
            message: 'Name in sixth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 6, 'name'),
            message: 'Name in seventh frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 7, 'name'),
            message: 'Name in eigth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 8, 'name'),
            message: 'Name in ninth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 9, 'name'),
            message: 'Name in tenth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Name is required'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name can not start with . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in first frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in second frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in third frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in fourth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in fifth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in sixth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in seventh frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in eigth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in ninth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Name in tenth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-name-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Name can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Name can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['snapchat_ad-ad_setup-name'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Name can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'headline'),
            message: 'Headline is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 0, 'headline'),
            message: 'Headline in first frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 1, 'headline'),
            message: 'Headline in second frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 2, 'headline'),
            message: 'Headline in third frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 3, 'headline'),
            message: 'Headline in fourth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 4, 'headline'),
            message: 'Headline in fifth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 5, 'headline'),
            message: 'Headline in sixth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 6, 'headline'),
            message: 'Headline in seventh frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 7, 'headline'),
            message: 'Headline in eigth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 8, 'headline'),
            message: 'Headline in ninth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 9, 'headline'),
            message: 'Headline in tenth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Headline is required'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline can not start with . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline in first frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Headline can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline in second frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Headline can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline in third frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Headline can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline in fourth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Headline can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline in fifth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Headline can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline in sixth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Headline can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline in seventh frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Headline can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline in eigth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Headline can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.headline?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: 'Headline in ninth frame can not start with / ! . ? - * ( ) , ; :',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: 'Headline can not start with / ! . ? - * ( ) , ; :'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.headline?.match(/\b\w{30,}\b/)`,
            message: "Headline can't contain words longer than 30 characters",
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w{31,}\b/ }],
                    severity: 'error',
                    message: "Headline can't contain words longer than 30 characters"
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.headline?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Headline can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Headline can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.headline?.length > 34`,
            message: 'Headline should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.headline?.length > 34`,
            message: 'Headline in first frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.headline?.length > 34`,
            message: 'Headline in second frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.headline?.length > 34`,
            message: 'Headline in third frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.headline?.length > 34`,
            message: 'Headline in fourth frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.headline?.length > 34`,
            message: 'Headline in fifth frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.headline?.length > 34`,
            message: 'Headline in sixth frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.headline?.length > 34`,
            message: 'Headline in seventh frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.headline?.length > 34`,
            message: 'Headline in eigth frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.headline?.length > 34`,
            message: 'Headline in ninth frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.headline?.length > 34`,
            message: 'Headline in tenth frame should be below 34 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 34,
                    severity: 'error',
                    message: 'Headline should be below 34 characters'
                }
            ]
        },

        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.headline?.length > 100`,
            message: 'Headline length should be below 100 characters',
            inputIdentifiers: ['snapchat_ad-ad_setup-headline-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    severity: 'error',
                    max: 100,
                    message: 'Headline length should be below 100'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'link'),
            message: 'Link is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 0, 'link'),
            message: 'Link in first frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 1, 'link'),
            message: 'Link in second frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 2, 'link'),
            message: 'Link in third frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 3, 'link'),
            message: 'Link in fourth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 4, 'link'),
            message: 'Link in fifth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 5, 'link'),
            message: 'Link in sixth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 6, 'link'),
            message: 'Link in seventh frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 7, 'link'),
            message: 'Link in eigth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 8, 'link'),
            message: 'Link in ninth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 9, 'link'),
            message: 'Link in tenth frame is required',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },

        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.link && !${brickAdSetup}.items[0].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.link && !${brickAdSetup}.items[0].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in first frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(https?:\/\/)?(?:www\.)\S+\.\S+\b/ }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.link && !${brickAdSetup}.items[1].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in second frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.link && !${brickAdSetup}.items[2].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in third frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.link && !${brickAdSetup}.items[3].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in fourth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.link && !${brickAdSetup}.items[4].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in fifth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.link && !${brickAdSetup}.items[5].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in sixth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.link && !${brickAdSetup}.items[6].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in seventh frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.link && !${brickAdSetup}.items[7].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in eigth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.link && !${brickAdSetup}.items[8].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in ninth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.link && !${brickAdSetup}.items[9].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in tenth frame is not a valid URL',
            inputIdentifiers: ['snapchat_ad-ad_setup-link-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        }
    ]
};

export default validators;
