import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'pinterest_adgroup',
    title: 'Pinterest ad group',
    channel: 'social',
    platform: 'pinterest',
    description: 'Create an ad group for a Pinterest campaign',
    addBrickText: 'Add ad group',
    outputAction: 'publish',
    availableParentBricks: ['pinterest_campaign'],
    availableChildBricks: [],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput' // gets settings.ts
        },
        {
            key: 'preview',
            title: 'Preview',
            type: 'component',
            component: 'preview'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        }
    ]
};

export default setup;
