import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Publish',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'data.settings.publish.channels.facebook.ad.name',
                itemType: 'input',
                label: 'Name',
                useValueObject: false
            },

            {
                type: 'text',
                model: 'data.settings.publish.channels.facebook.ad.audience_id',
                itemType: 'input',
                label: 'Audience id',
                useValueObject: false
            },
            {
                type: 'number',
                tooltip:
                    'Bid amount for this ad which will be used in auction instead of the ad set bid_amount, if specified. Any updates to the ad set bid_amount will overwrite this value with the new ad set value.',
                model: 'data.settings.publish.channels.facebook.ad.topline_id',
                label: 'Bid amount'
            },
            {
                type: 'text',
                model: 'data.settings.publish.channels.facebook.ad.conversion_domain',
                itemType: 'input',
                label: 'Conversion domain',
                tooltip:
                    'The domain where conversions happen. Required to create or update an ad in a campaign that shares data with a pixel. This field will be auto-populated for existing ads by inferring from destination URLs . Note that this field should contain only the first and second level domains, and not the full URL. For example facebook.com.',
                useValueObject: false
            },
            {
                type: 'text',
                model: 'data.settings.publish.channels.facebook.ad.date_format',
                itemType: 'input',
                tooltip: 'The format of the date',
                label: 'Date format',
                useValueObject: false
            },
            {
                type: 'number',
                tooltip: 'The sequence of the ad within the same campaign',
                model: 'data.settings.publish.channels.facebook.ad.disolay_sequence',
                label: 'Display sequence'
            },
            {
                type: 'number',
                tooltip: 'The ID of the draft ad.',
                model: 'data.settings.publish.channels.facebook.ad.disolay_sequence',
                label: 'Draft ad group id'
            },
            {
                type: 'buttonGroup',
                model: 'data.settings.publish.channels.facebook.engagement_audience',
                label: 'Engagement audience',
                tooltip: 'Flag to create a new audience based on users who engage with this ad',
                defaultValue: 'false',
                options: {
                    false: 'OFF',
                    true: 'ON'
                }
            },
            {
                type: 'buttonGroup',
                model: 'data.settings.publish.channels.facebook.include_demolink_hashes',
                label: 'Include demolink hashes',
                tooltip: 'Include the demolink hashes.',
                defaultValue: 'false',
                options: {
                    false: 'OFF',
                    true: 'ON'
                }
            },
            {
                type: 'number',
                tooltip: 'The ID of the draft ad.',
                model: 'data.settings.publish.channels.facebook.ad.priority',
                label: 'Priority'
            },
            {
                type: 'text',
                model: 'data.settings.publish.channels.facebook.ad.tracking_specs',
                itemType: 'input',
                label: 'Tracking specs',
                useValueObject: false
            }
        ]
    },
    {
        title: 'Planning',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'date',
                model: 'dateStart',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'dateEnd',
                label: 'End date'
            }
        ]
    }
];

export default settings;
