import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Channels',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'multiLevelCheckboxList',
                model: 'data.placements',
                items: [
                    {
                        key: 'meta',
                        label: 'Meta',
                        icon: 'meta',
                        children: [
                            {
                                key: 'meta_link_ad',
                                label: 'Meta link ad',
                                icon: 'folder_open',
                                children: [
                                    {
                                        key: 'meta_newsfeed',
                                        label: 'Newsfeed',
                                        icon: 'image'
                                    },
                                    {
                                        key: 'meta_marketplace',
                                        label: 'Marketplace',
                                        icon: 'image'
                                    },
                                    {
                                        key: 'meta_right_column',
                                        label: 'Right column',
                                        icon: 'image'
                                    }
                                ]
                            },
                            {
                                key: 'meta_stories',
                                label: 'Meta stories',
                                icon: 'image'
                            }
                        ]
                    },
                    {
                        key: 'tiktok',
                        label: 'Tiktok',
                        icon: 'tiktok',
                        children: [
                            {
                                key: 'tiktok_test',
                                label: 'Tiktok test',
                                icon: 'image'
                            },
                            {
                                key: 'tiktok_test2',
                                label: 'Tiktok test 2',
                                icon: 'image'
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        title: 'Special ad categories',
        itemType: 'block',
        type: 'multiInput',
        blockModel: '{{{MODEL_PUBLISH_METADATA}}}',
        opened: true,
        items: [
            {
                type: 'autocomplete',
                model: 'special_industries',
                label: 'Special ad category',
                tooltip: 'The campaign special ad category',
                options: ['HOUSING', 'EMPLOYMENT', 'CREDIT', 'POLITICS', 'GAMBLING']
            }
        ]
    },

    {
        title: 'Campaign details',
        itemType: 'block',
        type: 'multiInput',
        blockModel: '{{{MODEL_PUBLISH_METADATA}}}',
        opened: true,
        items: [
            // // TODO: To be discussed because when selecting "RESERVED" META marketing throws error
            // {
            //     type: 'buttonGroup',
            //     model: 'buying_type',
            //     label: 'Buying type',
            //     tooltip:
            //         'This field will help Facebook make optimizations to delivery, pricing, and limits. All ad sets in this campaign must match the buying type.',

            //     options: {
            //         AUCTION: 'Auction',
            //         RESERVED: 'Reserved'
            //     }
            // },
            {
                type: 'select',
                model: 'objective',
                label: 'Objective',
                tooltip: 'The ultimate goal of your marketing campaign.',
                options: {
                    AWARENESS: 'Awareness',
                    TRAFFIC: 'Traffic',
                    ENGAGEMENT: 'Engagement',
                    LEADS: 'Leads',
                    SALES: 'Sales',
                    APP_PROMOTION: 'App promotion'
                }
            },

            {
                type: 'select',
                model: 'status',
                label: 'Status',
                tooltip: 'Status',
                options: {
                    PAUSED: 'PAUSED',
                    ACTIVE: 'ACTIVE'
                }
            },
            {
                type: 'date',
                model: 'campaign_start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'campaign_stop_time',
                label: 'End date'
            },
            {
                type: 'buttonGroup',
                model: 'budget_optimize_on',
                label: 'Budget optimization',
                //  defaultValue: 'False',
                tooltip: 'Whether to enable Campaign Budget Optimization (CBO).',
                options: {
                    false: 'OFF',
                    true: 'ON'
                }
            }
        ]
    },
    {
        title: 'Budget',
        itemType: 'block',
        type: 'multiInput',
        blockModel: '{{{MODEL_PUBLISH_METADATA}}}',
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'campaign_budget_mode',
                label: 'Budget mode',
                defaultValue: 'DAILY',
                tooltip: 'Type of the budget',
                options: {
                    DAILY: 'DAILY',
                    LIFETIME: 'LIFETIME'
                }
            },
            {
                type: 'number',
                tooltip: 'Daily campaign budget',
                model: 'campaign_budget',
                label: 'Budget'
            }
        ]
    },
    {
        title: 'Planning',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'date',
                model: 'dateStart',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'dateEnd',
                label: 'End date'
            }
        ]
    }
];

export default settings;
