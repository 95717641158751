import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_PUBLISH_METADATA } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'settings.title',
                itemType: 'input',
                label: 'Title',
                fixedModel: true,
                maxLength: 150,
                useValueObject: false
            }
        ]
    },

    {
        title: 'Campaign setup',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'ad_account_id',
                identifier: 'snapchat_campaign-ad_account_id',
                label: 'Ad account',
                metadataType: 'snapchatAdAccount',
                config: {
                    organizationId: 'c225e821-066b-4328-a8ad-6da44c5ab771'
                },
                useValueObject: false
            },
            {
                type: 'select',
                model: 'objective',
                identifier: 'snapchat_campaign-objective',
                label: 'Objective',
                tooltip: 'The ultimate goal of your marketing campaign.',
                options: {
                    BRAND_AWARENESS: 'Brand awareness',
                    APP_INSTALLS: 'App installs',
                    APP_CONVERSION: 'App conversion',
                    VIDEO_VIEW: 'Video views',
                    WEB_CONVERSION: 'Traffic to website',
                    ENGAGEMENT: 'Community interaction',
                    LEAD_GENERATION: 'Lead generation',
                    CATALOG_SALES: 'Catalog sales',
                    PROMOTE_PLACES: 'Promote places'
                }
            },
            {
                type: 'select',
                model: 'status',
                identifier: 'snapchat_campaign-status',
                label: 'Status',
                tooltip: 'Status',
                options: {
                    PAUSED: 'PAUSED',
                    ACTIVE: 'ACTIVE'
                }
            },
            {
                type: 'date',
                model: 'start_time',
                identifier: 'snapchat_campaign-start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'end_time',
                identifier: 'snapchat_campaign-end_time',
                label: 'End date'
            },
            // {
            //     type: 'text',
            //     model: 'measurement_spec',
            //     label: 'Measurement specifications',
            //     placeholder: "e.g. '{'ios_app_id':'1234', 'android_app_url':'com.snapchat.android'}'",
            //     helperText: 'The apps to be tracked for this campaign',
            //     useValueObject: false
            // },
            // {
            //     type: 'buttonGroup',
            //     model: 'buy_model',
            //     label: 'Buy model',
            //     defaultValue: 'AUCTION',
            //     tooltip: 'The ultimate goal of your marketing campaign.',
            //     options: {
            //         AUCTION: 'Auction',
            //         RESERVED: 'Reserved'
            //     }
            // },
            {
                type: 'number',
                tooltip: 'Daily spend cap',
                identifier: 'snapchat_campaign-daily_budget_micro',
                model: 'daily_budget_micro',
                label: 'Daily spend cap'
            },
            {
                type: 'number',
                tooltip: 'Lifetime spend cap',
                model: 'lifetime_spend_cap_micro',
                identifier: 'snapchat_campaign-lifetime_spend_cap_micro',
                label: 'Lifetime spend cap'
            }
        ]
    },

    // {
    //     type: 'text',
    //     model: 'measurement_spec',
    //     label: 'Measurement specifications',
    //     placeholder: "e.g. '{'ios_app_id':'1234', 'android_app_url':'com.snapchat.android'}'",
    //     helperText: 'The apps to be tracked for this campaign',
    //     useValueObject: false
    // },
    // {
    //     type: 'buttonGroup',
    //     model: 'buy_model',
    //     label: 'Buy model',
    //     defaultValue: 'AUCTION',
    //     tooltip: 'The ultimate goal of your marketing campaign.',
    //     options: {
    //         AUCTION: 'Auction',
    //         RESERVED: 'Reserved'
    //     }
    // },

    {
        title: 'Planning',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'dateRange',
                model: 'settings.planning',
                label: 'Schedule date & time'
            }
        ]
    }
];

export default settings;
