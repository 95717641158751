import React, { useMemo } from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Translation from 'components/data/Translation';
import { FieldSetCopy } from 'components/input/Copy';
import CreativeSelector from 'components/input/CreativeSelector';
import Divider from 'components/ui-components-v2/Divider';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import { withResources } from 'components/data/Resources';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import brickSetup from './setup';

import './styles/ad-setup.scss';

interface Props {
    handleSelectCreatives: (creatives: CreativeV2[]) => void;
    snapchat_cta: {
        [key: string]: string;
    };
}

const SingleFrame: React.FC<Props> = ({ handleSelectCreatives, snapchat_cta }) => {
    const { adSetup, creatives, selectedFrame, onChange } = useAdSetupContext();
    const snapchatAdSetup: any = adSetup; // TODO Temporary because the adsetup is typed for meta and not in general

    const inputValidators = useMemo(() => {
        return {
            name: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-name-${selectedFrame}`),
            headline: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-headline-${selectedFrame}`),
            link: ValidateHelpers.getInputValidators('snapchat_ad', `snapchat_ad-ad_setup-link-${selectedFrame}`)
        };
    }, [selectedFrame]);

    const { multiple, canEditCreative, selectors, templateTypes = [] } = brickSetup?.config?.general?.creativeSelector || {};

    return (
        <Accordion
            square
            title={Translation.get('adSetup.main', 'bricks')}
            classes={{ root: 'ad-setup__accordion__root', details: 'ad-setup__accordion__details', summaryRoot: 'ad-setup__accordion__summary__root' }}>
            <div className="ad-setup__accordion__inputs">
                <CreativeSelector
                    onMutation={(creatives) => handleSelectCreatives(creatives)}
                    multiple={multiple}
                    canEditCreative={canEditCreative}
                    value={creatives}
                    selectors={selectors}
                    templateTypes={templateTypes}
                />
                <Divider />
                <FieldSetCopy
                    label={Translation.get('adSetup.snapchatAd.name', 'bricks')}
                    useValueObject={false}
                    onMutation={(newValue) => onChange('name', newValue, true)}
                    value={snapchatAdSetup?.items?.[0].name}
                    validators={inputValidators.name}
                />
                <FieldSetCopy
                    label={Translation.get('adSetup.snapchatAd.headline', 'bricks')}
                    useValueObject={false}
                    onMutation={(newValue) => onChange('headline', newValue, true)}
                    value={snapchatAdSetup?.items?.[0].headline}
                    validators={inputValidators.headline}
                />
                <FieldSetSelectInput
                    label={Translation.get('adSetup.snapchatAd.callToAction', 'bricks')}
                    useValueObject={false}
                    onMutation={(newValue) => onChange('cta', newValue)}
                    value={adSetup.cta}
                    options={snapchat_cta}
                />
                <FieldSetCopy
                    label={Translation.get('adSetup.snapchatAd.link', 'bricks')}
                    useValueObject={false}
                    onMutation={(newValue) => onChange('link', newValue, true)}
                    value={adSetup?.items?.[0].link}
                    validators={inputValidators.link}
                />
            </div>
        </Accordion>
    );
};

export default withResources(SingleFrame, ['snapchat_cta']);
