import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'settings.title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Publish',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'settings.publish.channels.pinterest.set.bid_strategy_type',
                label: 'Bid strategy',
                tooltip:
                    'Choose bid strategy for this campaign to suit your specific business goals. Each strategy has tradeoffs and may be available for certain optimization_goals',
                options: {
                    AUTOMATIC_BID: 'Automatic bid',
                    MAX_BID: 'Max bid',
                    TARGET_AVG: 'Target average'
                }
            },
            {
                type: 'select',
                model: 'settings.publish.channels.pinterest.set.billing_event',
                label: 'Billing event',
                tooltip: 'The billing event for this ad set',
                options: {
                    CLICKTHROUGH: 'Click through',
                    IMPRESSION: 'Impression',
                    VIDEO_V_50_MRC: 'Video view 2 sec when 50 of its content is on the screen'
                }
            },
            {
                type: 'buttonGroup',
                model: 'settings.publish.channels.pinterest.set.budget_type',
                label: 'Budget type',
                defaultValue: 'daily',
                tooltip: 'Type of the budget',
                options: {
                    CBO_ADGROUP: 'Campaign budget optimization ad grouo',
                    DAILY: 'Daily',
                    LIFETIME: 'Lifetime'
                }
            },
            {
                type: 'select',
                model: 'settings.publish.channels.pinterest.set.placement_group',
                label: 'Placement group',
                tooltip: 'Placement group',
                options: {
                    '': '',
                    ALL: 'All',
                    BROWSE: 'Browse',
                    OTHER: 'Other',
                    SEARCH: 'Search'
                }
            },
            {
                type: 'number',
                tooltip: 'Budget',
                model: 'settings.publish.channels.pinterest.set.budget.daily_budget',
                label: 'Budget'
            },
            {
                type: 'number',
                tooltip: 'Bid price',
                model: 'settings.publish.channels.pinterest.set.bid',
                label: 'Bid price'
            },
            {
                type: 'buttonGroup',
                model: 'settings.publish.channels.pinterest.set.pacing_delivery_type',
                label: 'Pacing',
                defaultValue: 'STANDART',
                tooltip: 'Pacing',
                options: {
                    ACCELERATED: 'Accelerated',
                    STANDARD: 'Standart'
                }
            },
            {
                type: 'number',
                tooltip: 'Set a limit to the number of times a promoted pin from this campaign can be impressed by a pinner within the past rolling 30 days.',
                model: 'settings.publish.channels.pinterest.set.lifetime_frequency_cap',
                condition: "campaign.settings.publish.channels.pinterest.set.billing_event==='IMPRESSIONS'",
                label: 'Lifetime frequency cap'
            },
            {
                type: 'date',
                model: 'settings.publish.channels.pinterest.set.schedule_start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'settings.publish.channels.snapchat.set.schedule_end_time',
                condition: "campaign.settings.publish.channels.pinterest.set.budget.type!=='LIFETIME'",
                label: 'End date'
            },
            {
                type: 'divider',
                label: 'Targeting',
                itemType: 'input',
                key: 'ea6ad26d-9d0d-4f6c-b4a9-1e33c41f9218',
                model: 'settings.general.publish.divider'
            },
            {
                type: 'autocomplete',
                model: 'settings.publish.channels.pinterest.set.targeting_spec.location',
                label: 'Countries',
                options: ['Bulgaria', 'Netherlands', 'United Kingdom', 'Belgium', 'Turkey']
            },
            {
                type: 'text',
                model: 'settings.publish.channels.pinterest.set.targeting_spec.audience_include',
                label: 'Audience incldude',
                placeholder: "e.g. '312312213,3123123213'",
                useValueObject: false
            },
            {
                type: 'text',
                model: 'settings.publish.channels.pinterest.set.targeting_spec.audience_exclude',
                label: 'Audience excldude',
                placeholder: "e.g. '312312213,3123123213'",
                useValueObject: false
            },
            {
                type: 'autocomplete',
                model: 'settings.publish.channels.snapchat.set.targeting_spec.locale',
                label: 'Languages',
                options: ['Bulgarian', 'Dutch', 'English', 'French', 'Turkish']
            },
            {
                type: 'checkboxList',
                model: 'settings.publish.channels.snapchat.set.targeting_spec.gender',
                label: 'Genders',
                selectType: 'multiple',
                options: {
                    male: 'Male',
                    female: 'Female',
                    unknown: 'Unknown'
                }
            },
            {
                type: 'checkboxList',
                model: 'settings.publish.channels.snapchat.set.targeting_spec.app_type',
                label: 'App type',
                selectType: 'multiple',
                options: {
                    android_mobile: 'Android mobile',
                    android_tablet: 'Android tablet',
                    ipad: 'IPad',
                    iphone: 'IPhone',
                    web: 'Web',
                    web_mobile: 'Web mobile'
                }
            },
            {
                type: 'checkboxList',
                model: 'settings.publish.channels.snapchat.set.targeting_spec.targeting_strategy',
                label: 'Targeting strategy',
                selectType: 'multiple',
                options: {
                    CHOOSE_YOUR_OWN: 'Choose your own',
                    FIND_NEW_CUSTOMERS: 'Find new customers',
                    RECONNECT_WITH_USERS: 'Reconnect with users'
                }
            },
            {
                type: 'autocomplete',
                model: 'settings.publish.channels.pinterest.set.targeting_spec.age',
                label: 'Age groups',
                options: ['18-24', '21+', '25-34', '35-44', '45-49', '55-54', '55-64', '65+']
            },
            {
                type: 'autocomplete',
                model: 'settings.publish.channels.pinterest.set.targeting_spec.interest',
                label: 'Interests',
                options: ['Programming', 'Workout', 'Fishing']
            }
        ]
    },
    {
        title: 'Planning',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'dateRange',
                model: 'settings.planning',
                label: 'Schedule date & time'
            }
        ]
    }
];

export default settings;
